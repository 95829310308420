import { Card, Col, Divider, Row, Statistic } from "antd";
import { statusOptions } from "../constants";
import { useStatistic } from "../hooks/statistic";

const Dashboard = () => {
  const { statistic, loading } = useStatistic();
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic
              title="總共"
              value={statistic ? statistic["total"] : undefined}
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="開放/不開放"
              value={
                statistic
                  ? `${statistic["public"]}/${
                      statistic["total"] - statistic["public"]
                    }`
                  : undefined
              }
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
      <Divider></Divider>
      <Row gutter={[16, 16]}>
        {Object.values(statusOptions).map((i) => (
          <Col span={6} key={i}>
            <Card>
              <Statistic
                title={i}
                value={statistic ? statistic[i] : undefined}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Dashboard;
