import { Divider, Typography } from "antd";
import AddOptionButton from "../components/option/AddOptionButton";
import OptionTable from "../components/option/OptionTable";

const { Title } = Typography;

const Option = ({ type, name }) => {
  return (
    <>
      <Title level={3}>{name}</Title>
      <Divider></Divider>
      <AddOptionButton type={type} name={name} />
      <OptionTable type={type} />
    </>
  );
};

export default Option;
