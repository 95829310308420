import { useContext } from "react";
import { useQuery } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useStatistic = () => {
  const { token } = useContext(AuthContext);
  const query = useQuery(["statistic", token], () =>
    api.Record.getStatistic(token)
  );

  return {
    statistic: query.isSuccess ? query.data : undefined,
    loading: query.isLoading,
  };
};
