import { Button, Result } from "antd";

function Page404() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="抱歉，你要找的頁面不存在。"
      extra={<Button type="primary">回到首頁</Button>}
    />
  );
}

export default Page404;
