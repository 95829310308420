import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useRecord = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);
  const query = useQuery(["records", token], () => api.Record.all(token));

  const update = useMutation(
    ({ id, data }) => api.Record.update(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("records");
        queryClient.invalidateQueries("statistic");
      },
    }
  );

  const del = useMutation((id) => api.Record.delete(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("records");
      queryClient.invalidateQueries("statistic");
    },
  });

  return {
    records: query.isSuccess ? query.data : [],
    loading: query.isLoading,
    updateRecord: update,
    deleteRecord: del,
  };
};
