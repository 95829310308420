import { Table } from "antd";
import { useOption } from "../../hooks/option";
import DeleteButton from "../DeleteButton";

const OptionTable = ({ type }) => {
  const { options, loading, deleteOption } = useOption({ type });

  const columns = [
    { key: "name", dataIndex: "name", title: "名稱" },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <DeleteButton
          title={`你確定要刪除「${record.name}」? `}
          onDelete={() => {
            deleteOption.mutate(record.id);
          }}
        />
      ),
    },
  ];

  const data = options.map((d, i) => {
    d.key = i;
    return d;
  });

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="mt-3"
      loading={loading}
      scroll={{ x: 536 }}
    ></Table>
  );
};

export default OptionTable;
