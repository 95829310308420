import axios from "axios";

const API_ROOT = process.env.REACT_APP_API_ROOT;
const TOKEN_KEY = "jwt";

function authHeaders(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

axios.interceptors.response.use(
  (response) => response,
  function (error) {
    if (error.response) {
      const { status } = error.response;
      if (status === 401 || status === 403) {
        window.localStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.location.href = `/login`;
      }
    }
    return Promise.reject(error);
  }
);

const requests = {
  del: (url, token) =>
    axios
      .delete(`${API_ROOT}${url}`, authHeaders(token))
      .then((res) => res.data),
  get: (url, token) =>
    axios.get(`${API_ROOT}${url}`, authHeaders(token)).then((res) => res.data),
  put: (url, body, token) =>
    axios
      .put(`${API_ROOT}${url}`, body, authHeaders(token))
      .then((res) => res.data),
  post: (url, body, token) =>
    axios
      .post(`${API_ROOT}${url}`, body, authHeaders(token))
      .then((res) => res.data),
};

const Login = {
  login: (username, password) => {
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    return axios.post(`${API_ROOT}/v1/login/access-token`, formData);
  },
};

const Record = {
  all: (token) => requests.get(`/v1/record/`, token),
  update: (id, data, token) => requests.put(`/v1/record/${id}`, data, token),
  delete: (id, token) => requests.del(`/v1/record/${id}`, token),
  getStatistic: (token) => requests.get(`/v1/record/statistic`, token),
};

const Option = {
  getByType: (type, token) => requests.get(`/v1/option/${type}`, token),
  create: (data, token) => requests.post(`/v1/option`, data, token),
  delete: (id, token) => requests.del(`/v1/option/${id}`, token),
};

const api = {
  Login,
  Record,
  Option,
};

export default api;
