import { Form, Input } from "antd";

const OptionForm = ({ onFinish, formId, initialValues }) => {
  const [form] = Form.useForm();

  return (
    <Form
      id={formId}
      form={form}
      preserve={false}
      layout={"vertical"}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        label="名稱"
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
    </Form>
  );
};

export default OptionForm;
