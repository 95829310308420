import {
  DatabaseOutlined,
  HomeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Space, Tag } from "antd";
import { useContext } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { AuthContext } from "../components/AuthContext";
import Dashboard from "./Dashboard.jsx";
import Option from "./Option.jsx";
import Record from "./Record.jsx";

const { Content, Sider, Header } = Layout;
const { SubMenu } = Menu;

function Home() {
  const { logout, token, email } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const active = location.pathname.split("/").splice(-1)[0];

  if (!token) {
    return <Redirect to="/login" />;
  }

  const handleClickLogout = () => {
    logout();
    history.push("/login");
  };

  return (
    <Layout>
      <Sider breakpoint="lg" collapsedWidth="0">
        <div className="my-4 text-center text-light">
          {process.env.REACT_APP_NAME}
          <br />
          管理後台
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys="dashboard"
          selectedKeys={[active]}
        >
          <Menu.Item key="dashboard" icon={<HomeOutlined />}>
            <Link to="/dashboard">首頁</Link>
          </Menu.Item>
          <Menu.Item key="record" icon={<DatabaseOutlined />}>
            <Link to="/record">紀錄</Link>
          </Menu.Item>
          <SubMenu key="sub_donation" icon={<SettingOutlined />} title="設定">
            <Menu.Item key="office">
              <Link to="/office">區辦</Link>
            </Menu.Item>
            <Menu.Item key="play">
              <Link to="/play">戲劇名稱</Link>
            </Menu.Item>
            <Menu.Item key="location_type">
              <Link to="/location_type">地點類別</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout>
        <Header className="bg-white d-flex justify-content-end align-items-center">
          <Space>
            <Tag color="blue">{email}</Tag>
            <Button onClick={handleClickLogout}>登出</Button>
          </Space>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: "#fff",
            minHeight: "100vh",
          }}
        >
          <Switch>
            <Route path="/dashboard" key="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/record" key="/record">
              <Record />
            </Route>
            <Route path="/office" key="/office">
              <Option type="office" name="區辦" />
            </Route>
            <Route path="/play" key="/play">
              <Option type="play" name="戲劇名稱" />
            </Route>
            <Route path="/location_type" key="/location_type">
              <Option type="location_type" name="地點類別" />
            </Route>
            <Redirect from="/" to="/dashboard" />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Home;
