import { Button, Result } from "antd";

function Page404() {
  return (
    <Result
      status="500"
      title="500"
      subTitle="抱歉，似乎有些錯誤。"
      extra={<Button type="primary">回到首頁</Button>}
    />
  );
}

export default Page404;
