import RecordTable from "../components/record/RecordTable";

const Record = () => {
  return (
    <>
      <RecordTable />
    </>
  );
};

export default Record;
