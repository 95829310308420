import { useEffect, useState } from "react";
import { Modal, Button, Checkbox, Divider, Row, Col } from "antd";
import ExportToExcel from "./ExportToExcel";

function DownloadButton({ data, options, fileName }) {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [columns, setColumns] = useState({});

  const plainOptions = options.map((i) => i.value);
  const [checkedList, setCheckedList] = useState(plainOptions);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);

  useEffect(() => {
    setSelected(
      data.map((i) =>
        Object.keys(i)
          .filter((j) => checkedList.includes(j))
          .reduce((obj, key) => {
            obj[key] = i[key];
            return obj;
          }, {})
      )
    );
 }, [checkedList, data ]);

  useEffect(() => {
    setColumns(
      options.filter(i => checkedList.includes(i.value)).reduce(
        (obj, item) => Object.assign(obj, { [item.value]: item.label}), {}
      )
    )
  }, [options, checkedList])

  const checkboxes = options.map((i) => (
    <Col sm={24} md={12} lg={12} xl={12} key={i.value}>
      <Checkbox value={i.value} key={i.value}>{i.label}</Checkbox>
    </Col>
  ));

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const showModal = (e) => {
    e.stopPropagation();
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal} key="download">下載</Button>
      <Modal
        title={`有${data.length}筆資料，請選擇要下載的欄位`}
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <Button key="close" onClick={handleCancel}>
            取消
          </Button>,
          <ExportToExcel
          key="export"
          apiData={selected}
          fileName={fileName}
          columnMap={columns}
          />,
        ]}
      >
        <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
        >
          全部
        </Checkbox>
        <Divider />
        <Checkbox.Group style={{ width: "100%" }} value={checkedList} onChange={onChange}>
          <Row>{checkboxes}</Row>
        </Checkbox.Group>
     </Modal>
    </>
  );
}

export default DownloadButton;
