import { Descriptions, Divider, Form, Input, Select, Tag } from "antd";
import { statusOptions } from "../../constants";

const { Option } = Select;
const { TextArea } = Input;

const RecordForm = ({ onFinish, formId, initialValues, record }) => {
  return (
    <>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="送出日期">
          {record.send_date}
        </Descriptions.Item>
        <Descriptions.Item label="聯絡人">
          {record.contact_person}
        </Descriptions.Item>
        <Descriptions.Item label="email">{record.email}</Descriptions.Item>
        <Descriptions.Item label="手機">{record.mobile}</Descriptions.Item>
        <Descriptions.Item label="電話">{record.home_phone}</Descriptions.Item>
        <Descriptions.Item label="所屬區辦">{record.office}</Descriptions.Item>
        <Descriptions.Item label="劇團名稱">{record.troupe}</Descriptions.Item>
        <Descriptions.Item label="戲劇名稱">{record.play}</Descriptions.Item>
        <Descriptions.Item label="地點">{record.location}</Descriptions.Item>
        <Descriptions.Item label="地點類型">
          {record.location_type}
        </Descriptions.Item>
        <Descriptions.Item label="地址">
          {record.address_city}
          {record.address_site}
          {record.address_street}
        </Descriptions.Item>
        <Descriptions.Item label="演出日期">{record.date}</Descriptions.Item>
        <Descriptions.Item label="演出時間">
          {record.start_time} ~ {record.end_time}
        </Descriptions.Item>
        <Descriptions.Item label="演出人數">
          {record.performers_num}
        </Descriptions.Item>
        <Descriptions.Item label="預估觀賞人數">
          {record.audiences_num}
        </Descriptions.Item>
        <Descriptions.Item label="是否開放觀賞">
          {record.is_public ? <Tag color="blue">開放</Tag> : <Tag>不開放</Tag>}
        </Descriptions.Item>
        <Descriptions.Item label="使用者備註">{record.note}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Form
        id={formId}
        preserve={false}
        layout={"vertical"}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.Item
          label="狀態"
          name="status"
          rules={[
            {
              required: true,
              message: "請選擇",
            },
          ]}
        >
          <Select disabled={record.status === statusOptions.insured}>
            {Object.values(statusOptions).map((i) => (
              <Option value={i} key={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="管理員通知" name="manager_response">
          <TextArea rows={4}></TextArea>
        </Form.Item>
      </Form>
    </>
  );
};

export default RecordForm;
