import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Tooltip,
  Typography,
} from "antd";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../components/AuthContext";

const { Title } = Typography;
const { ErrorBoundary } = Alert;

function Login() {
  const history = useHistory();
  const { login, error, loading } = useContext(AuthContext);

  const onFinish = (values) => {
    login(values.username, values.password, values.remember).then(() =>
      history.push("/")
    );
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh" }}
    >
      <Col xs={12} sm={10} md={10} lg={10} xl={5}>
        <Title level={2} style={{ textAlign: "center" }}>
          {process.env.REACT_APP_NAME}
          <br />
          管理後台
        </Title>
        <Form name="basic" onFinish={onFinish}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: "請輸入註冊的 email" }]}
          >
            <Input prefix={<UserOutlined />} placeholder="email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "請輸入密碼" }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="密碼" />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>
              <Tooltip title="請勿在公用電腦上點選">記住我</Tooltip>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              登入
            </Button>
          </Form.Item>
          {error ? (
            <ErrorBoundary>
              <Alert message={error} type="error" closable />
            </ErrorBoundary>
          ) : null}
        </Form>
      </Col>
    </Row>
  );
}

export default Login;
