import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Space,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { statusOptions } from "../../constants";
import { downloadOptions } from "../../downloadOptions";
import { useRecord } from "../../hooks/record";
import { dateFormat, timeFormat } from "../../utils";
import DeleteButton from "../DeleteButton";
import DownloadButton from "../DownloadButton";
import EditRecordButton from "./EditRecordButton";

const { RangePicker } = DatePicker;

const RecordTable = () => {
  const { records, loading, updateRecord, deleteRecord } = useRecord();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [data, setData] = useState([]);

  const onSearch = (e) => {
    const value = e.target.value;
    setData(
      records
        .filter(
          (i) =>
            i.troupe.includes(value) ||
            i.contact_person.includes(value) ||
            i.location.includes(value)
        )
        .map((d) => {
          d.key = d.id;
          return d;
        })
    );
    setSelectedData([]);
    setSelectedRowKeys([]);
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedData(
      data
        .filter((i) => selectedRowKeys.includes(i.key))
        .map((i) => {
          i.start_time = moment(i.start_time, "HH:mm:ss").format(timeFormat);
          i.end_time = moment(i.end_time, "HH:mm:ss").format(timeFormat);
          return i;
        })
    );
  };

  const getOptions = (arr, key) => {
    return [...new Set(arr.map((d) => d[key]))]
      .filter((d) => (d !== null) & (d !== ""))
      .sort()
      .map((d) => {
        return { text: d, value: d };
      });
  };

  const onFilter = (key) => {
    return (value, record) => {
      if (record[key]) {
        return record[key] === value;
      }
    };
  };

  const office = getOptions(records, "office");
  const troupe = getOptions(records, "troupe");
  const play = getOptions(records, "play");
  const location_type = getOptions(records, "location_type");

  const onFilterDate = (values) => {
    setData(
      records
        .map((d) => {
          d.key = d.id;
          return d;
        })
        .filter(
          (i) =>
            i.date &&
            moment(i.date) >= values["date"][0] &&
            moment(i.date) <= values["date"][1]
        )
    );
  };

  const menu = (
    <Menu>
      <Menu.Item key="checked">
        <Button type="text" onClick={() => onSubmit(statusOptions.checked)}>
          審核
        </Button>
      </Menu.Item>
      <Menu.Item key="returned">
        <Button type="text" onClick={() => onSubmit(statusOptions.returned)}>
          退件
        </Button>
      </Menu.Item>
      <Menu.Item key="insured">
        <Button type="text" onClick={() => onSubmit(statusOptions.insured)}>
          保險
        </Button>
      </Menu.Item>
    </Menu>
  );
  const onSubmit = (value) => {
    message.info("正在更新中");
    Promise.all(
      selectedData.map((i) => {
        return updateRecord.mutateAsync({
          id: i.id,
          data: { status: value },
        });
      })
    ).then(() => {
      message.info("已更新完成");
      setSelectedRowKeys([]);
      setSelectedData([]);
    });
  };

  const columns = [
    { key: "id", dataIndex: "id", title: "No" },
    { key: "send_date", dataIndex: "send_date", title: "送出日期" },
    { key: "date", dataIndex: "date", title: "演出日期" },
    {
      key: "contact_person",
      title: "聯絡人",
      dataIndex: "contact_person",
    },
    { key: "email", dataIndex: "email", title: "email" },
    { key: "mobile", dataIndex: "mobile", title: "手機" },
    { key: "home_phone", dataIndex: "home_phone", title: "電話" },
    {
      key: "office",
      dataIndex: "office",
      title: "所屬區辦",
      filters: office,
      onFilter: onFilter("office"),
    },
    {
      key: "troupe",
      dataIndex: "troupe",
      title: "劇團名稱",
      filters: troupe,
      onFilter: onFilter("troupe"),
    },
    {
      key: "play",
      dataIndex: "play",
      title: "戲劇名稱",
      filters: play,
      onFilter: onFilter("play"),
    },
    { key: "location", dataIndex: "location", title: "地點" },
    {
      key: "location_type",
      dataIndex: "location_type",
      title: "地點類型",
      filters: location_type,
      onFilter: onFilter("location_type"),
    },
    {
      key: "address",
      title: "地址",
      render: (text, record) => {
        return (
          <div>
            {record.address_city}
            {record.address_site}
            {record.address_street}
          </div>
        );
      },
    },
    {
      key: "time",
      title: "演出時間",
      render: (text, record) => {
        return (
          <div>
            {moment(record.start_time, "HH:mm:ss").format(timeFormat)}~
            {moment(record.end_time, "HH:mm:ss").format(timeFormat)}
          </div>
        );
      },
    },
    { key: "performers_num", dataIndex: "performers_num", title: "演出人數" },
    { key: "audiences_num", dataIndex: "audiences_num", title: "預估觀眾人數" },
    {
      key: "is_public",
      title: "是否開放觀賞",
      render: (text, record) =>
        record.is_public ? <Tag color="blue">開放</Tag> : <Tag>不開放</Tag>,
      filters: [
        {
          text: "開放",
          value: true,
        },
        {
          text: "不開放",
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_public === value,
    },
    {
      key: "status",
      title: "狀態",
      render: (text, record) => {
        let color = "red";
        switch (record.status) {
          case statusOptions.send:
            color = "gold";
            break;
          case statusOptions.checked:
            color = "blue";
            break;
          case statusOptions.returned:
            color = "red";
            break;
          case statusOptions.insured:
            color = "green";
            break;
          default:
            color = "";
            break;
        }
        return <Tag color={color}>{record.status}</Tag>;
      },
      filters: Object.values(statusOptions).map((d) => {
        return { text: d, value: d };
      }),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    { key: "note", dataIndex: "note", title: "使用者備註" },
    { key: "manager_response", dataIndex: "manager_response", title: "通知" },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <>
          <Space>
            <EditRecordButton record={record} />
            <DeleteButton
              title={`你確定要刪除場次「${record.id}」? `}
              onDelete={() => {
                deleteRecord.mutate(record.id);
              }}
            />
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Space>
          <Dropdown overlay={menu} placement="bottomLeft">
            <Button disabled={selectedRowKeys.length === 0} loading={loading}>
              操作
            </Button>
          </Dropdown>
          <DownloadButton
            data={selectedData}
            options={downloadOptions}
            fileName={"紀錄"}
          />
        </Space>
        <Form onFinish={onFilterDate}>
          <Space>
            <Input
              style={{ width: 300 }}
              placeholder="過濾劇團名稱、聯絡人、地點"
              onChange={onSearch}
            ></Input>
            <Form.Item
              noStyle
              name="date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <RangePicker
                format={dateFormat}
                allowClear={false}
                ranges={{
                  這個月: [moment().startOf("month"), moment().endOf("month")],
                }}
              />
            </Form.Item>
            <Button htmlType="submit">搜尋</Button>
          </Space>
        </Form>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }}
        size="small"
        loading={loading}
        scroll={{ x: 536 }}
        pagination={{
          pageSizeOptions: [10, 20, 50, 100, 1000, 2000, 5000]
        }}
      ></Table>
    </>
  );
};

export default RecordTable;
