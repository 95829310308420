const downloadOptions = [
  { label: "場次編號", value: "id" },
  { label: "送出日期", value: "send_date" },
  { label: "聯絡人", value: "contact_person" },
  { label: "email", value: "email" },
  { label: "手機", value: "mobile" },
  { label: "電話", value: "home_phone" },
  { label: "所屬區辦", value: "office" },
  { label: "劇團名稱", value: "troupe" },
  { label: "戲劇名稱", value: "play" },
  { label: "地址縣市", value: "address_city" },
  { label: "地址區域", value: "address_site" },
  { label: "地址路名", value: "address_street" },
  { label: "地點", value: "location" },
  { label: "地點類別", value: "location_type" },
  { label: "演出日期", value: "date" },
  { label: "演出開始時間", value: "start_time" },
  { label: "演出結束時間", value: "end_time" },
  { label: "演出人數", value: "performers_num" },
  { label: "預估觀眾人數", value: "audiences_num" },
  { label: "是否開放觀賞", value: "is_public" },
  { label: "狀態", value: "status" },
  { label: "使用者備註", value: "note" },
  { label: "管理員回應", value: "manager_response" },
];

export { downloadOptions };
