import React, { useState } from "react";
import { useRecord } from "../../hooks/record";
import EditButton from "../EditButton";
import RecordForm from "./RecordForm";

const EditRecordButton = ({ record }) => {
  const { updateRecord } = useRecord();
  const [visible, setVisible] = useState(false);
  const formId = "edit_record";

  const onFinish = (values) => {
    updateRecord.mutateAsync({ id: record.id, data: values }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={"紀錄"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateRecord.isLoading}
      children={
        <RecordForm
          formId={formId}
          initialValues={record}
          record={record}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default EditRecordButton;
