import React, { useState } from "react";
import { useOption } from "../../hooks/option";
import AddButton from "../AddButton";
import OptionForm from "./OptionForm";

const AddOptionButton = ({ type, name }) => {
  const { createOption } = useOption({ type });
  const [visible, setVisible] = useState(false);
  const formId = "add_option";
  const initialValues = {
    name: "",
  };

  const onFinish = (values) => {
    createOption
      .mutateAsync({ data: { ...values, option_type: type } })
      .then(() => {
        setVisible(false);
      });
  };

  return (
    <AddButton
      name={name}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={createOption.isLoading}
      children={
        <OptionForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
        />
      }
    />
  );
};

export default AddOptionButton;
