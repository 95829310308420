import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useOption = ({ type }) => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);
  const query = useQuery(["options", { token, type }], () =>
    api.Option.getByType(type, token)
  );

  const create = useMutation(({ data }) => api.Option.create(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("options");
    },
  });

  const del = useMutation((id) => api.Option.delete(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("options");
    },
  });

  return {
    options: query.isSuccess ? query.data.sort((a, b) => b.id - a.id) : [],
    loading: query.isLoading,
    createOption: create,
    deleteOption: del,
  };
};
